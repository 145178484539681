import React, { useEffect, useState } from 'react';
import { Box, Button, Card, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';

import Modal from '@mui/material/Modal';
import { useDispatch } from 'react-redux';
import { updatebookservice } from '../../slices/customer';
import { Link, useNavigate } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

const Popup = ({modalte, open, setOpen, serData, setLoader}) => {

  const [reason, setReason] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleClose = () =>{
    setOpen(false);
  } 

  const handleReason = (event) =>{
    setReason(event.target.value)
  }

  const handleReject = (id) =>{
    const formData = {
      status : "Rejected",
      reason : reason
    }
    setLoader(true)
    dispatch(updatebookservice({id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  const handleApprove = (id) =>{
    console.log(id);
    const formData = {
      status : "Confirmed",
    }
    setLoader(true)
    dispatch(updatebookservice({id, formData}))
    .then(() => {
      setLoader(false);
    })
    .catch(()=>{
      setLoader(false);
    })
  }

  const handleService = (id) =>{
    setOpen(false);
  }

    return (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          {serData?.filter(ab => ab?.status !== "Rejected").map((va, index)=>{
            return(
            <Card key={index} sx={{ boxShadow: "none", border: "1px solid #bababa", mb: 1, width:'100%'}}>
          <Grid container rowSpacing={2} sx={{p: 2}} alignItems='center'>
              <Grid item xs={2}>
                  <Typography variant='body1'>Chassis No</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.chasis_number}</Typography>
              </Grid>
              <Grid item xs={2}>
                  <Typography variant='body1'>Service Type</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.service_type}</Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='body1'>Date and Time</Typography>
                  <Typography sx={{fontWeight:'bold'}}>{va?.service_date} {va?.service_time}</Typography>
              </Grid>
              <Grid item xs={2}>
              {va?.status === "Pending" ? 
              <Button variant='contained' color='success' onClick={()=>handleApprove(va?.id)} sx={{textAlign:'center', mt: 2}}>Approve</Button>
              :
              <label style={{background:'green', color:'#fff', padding: 2}}>{va?.status}</label>              }
              </Grid>
              <Grid item xs={3}>
              {va?.status === "Pending" ? 
              <>
              <FormControl size='small' fullWidth>
  <InputLabel id="demo-simple-select-label">Reject Reason</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={reason}
    label="Reject Reason"
    onChange={handleReason}
  >
    <MenuItem value="Reason 1">Reason 1</MenuItem>
    <MenuItem value="Reason 2">Reason 2</MenuItem>
    <MenuItem value="Reason 3">Reason 3</MenuItem>
  </Select>
</FormControl>
              <Button variant='outlined' color='success' onClick={()=> handleReject(va?.id)} sx={{textAlign:'center', mt: 2}}>Reject</Button>
              </>
              :
              <Link to={`/customer/view/${va?.customer_id}/newservice`} state={{cusData: {id: va.customer_id}}}>
              <Button variant='contained' color='success' onClick={()=>handleService(va?.customer_id)} sx={{textAlign:'center'}}>Make Service</Button>
              </Link>
              }
              </Grid>
          </Grid>
      </Card>
            )
          })}
          <Button variant='contained' onClick={handleClose} sx={{textAlign:'center', mt: 2}}>Close</Button>
          </Box>
        </Box>
      </Modal>
  );
}

export default Popup;