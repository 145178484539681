import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SearchButton from "../../components/search/Search";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloudUpload from "../../components/button/Cloudupload";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { createenquiry, getenquiry } from "../../slices/enquiry";
import { getregion } from "../../slices/setting";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import CallIcon from '@mui/icons-material/Call';
import MessageIcon from '@mui/icons-material/Message';
import EmailIcon from '@mui/icons-material/Email';
import BasicTabs from "../../components/tabs/Enquirytab";

const Viewenquiry = ({ setLoader, show }) => {

  const params = useParams();

  const id = params.id;

  const {enquiry: enquiryData} = useSelector((state) => state.enquiry) || [];

  const viewData =  enquiryData.filter(obj => obj.id === Number(id))[0] || [];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const arrowBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    setLoader(true)
      dispatch(getenquiry())
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
  }, [])

  return (
    <div className="content" style={{ marginLeft: show ? "220px" : "10px" }}>
      <Box>
        <Grid container spacing={3} sx={{ paddingBottom: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: 0,
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <ArrowBackIcon onClick={arrowBack} sx={{ cursor: "pointer" }} />
            <Typography variant="h5" sx={{fontWeight:'bold'}}>View Enquire</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ paddingTop: 3, display: "flex", alignItems: "center" }}
          >
            <Typography variant="h6">
              {viewData.title} {viewData.first_name} {viewData.last_name}
            </Typography>
            <Typography
              variant="p"
              sx={{
                bgcolor: "#0b4c7c",
                color: "#fff",
                px: 1,
                borderRadius: "10px",
                ml: 1,
              }}
            >
              {viewData.enquiry_type}
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "end" }}>
          <Link to='/customer/new' state={{ cusData: viewData }}>
            <Button size="small" variant="contained">
              Convert to Customer
            </Button>
          </Link>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Phone Number</Typography>
            <Typography variant="body1">{viewData.phone}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">GST Number</Typography>
            <Typography variant="body1">{viewData.gst_number}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Email ID</Typography>
            <Typography variant="body1">{viewData.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">Location</Typography>
            <Typography variant="body1">{viewData.location}</Typography>
          </Grid>
          <Grid item xs={1}>
            <Typography variant="body2">Test Drive</Typography>
            <Typography variant="body1">{viewData.testdrive ? viewData.testdrive : '-'}</Typography>
          </Grid>
          <Grid item xs={1}>
            {viewData.testdrive === "YES" && <img src={viewData?.document} alt="proof" width={80}></img>}
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "end" }}>
            <Box>
            <a href={`tel:${viewData.phone}`}>
              <Button
                sx={{
                  background: "#3d07dc",
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                }}
              >
                <CallIcon sx={{ color: "#fff", fontSize: 17 }}></CallIcon>
              </Button>
              </a>
              <a href={`mailto:${viewData.email}`}>
              <Button
                sx={{
                  background: "#3d07dc",
                  marginRight: 1,
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                }}
              >
                <MessageIcon sx={{ color: "#fff", fontSize: 17 }}></MessageIcon>
              </Button>
              </a>
              <a href={`mailto:${viewData.email}`}>
              <Button
                sx={{
                  background: "#3d07dc",
                  height: "30px",
                  width: "30px",
                  minWidth: 0,
                }}
              >
                <EmailIcon sx={{ color: "#fff", fontSize: 17 }}></EmailIcon>
              </Button>
              </a>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body2">Feedback</Typography>
            <Typography variant="body1">{viewData.feedback}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <BasicTabs viewData={viewData} setLoader={setLoader}/>
            </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Viewenquiry;
