import React, {useState} from 'react';
import Dashboard from './pages/dashboard/Dashboard.js';
import { Route, Routes } from 'react-router-dom';
import Layout from './Layout';
import Login from './pages/login/Login.js';
import Orders from './pages/orders/Orders.js';
import Inventory from './pages/inventory/Inventory.js';
import Warranty from './pages/warranty/Warranty.js';
import Challan from './pages/deliverychallan/Challan.js';
import Report from './pages/report/Report.js';
import Setting from './pages/settings/Setting.js';
import Layout2 from './Layout2.js';
import Home from './pages/home/Home.js';
import Integration from './pages/integration/Integration.js';
import Aboutus from './pages/aboutus/Aboutus.js';
import Contact from './pages/Contact/Contact.js';
import ProtectedRoute from './utils/ProtectedRoute.js';
import Createuser from './pages/usermanagement/Createuser.js';
import AddChallan from './pages/deliverychallan/Addchallan.js';
import Usermanagement from './pages/usermanagement/Usermanagement.js';
import Purchases from './pages/purchases/Purchases.js';
import Newpurchase from './pages/purchases/Newpurchases.js';
import Purchasedetail from './pages/purchases/Purchasesdetail.js';
import Enquiry from './pages/enquiry/Enquiry.js';
import Addenquiry from './pages/enquiry/Addenquiry.js';
import Viewenquiry from './pages/enquiry/Viewenquiry.js';
import Customer from './pages/customers/Customer.js';
import Addcustomer from './pages/customers/Addcustomer.js';
import Viewcustomer from './pages/customers/Viewcustomer.js';
import Addinvoice from './pages/customers/Addinvoice.js';
import Addpayment from './pages/customers/Addpayment.js';
import Addservice from './pages/customers/Addservice.js';
import Addwarranty from './pages/customers/Addwarranty.js';
import Viewpayment from './pages/customers/Viewpayment.js';
import Viewinvoice from './pages/customers/Viewinvoice.js';
import Viewservice from './pages/customers/Viewservice.js';
import Warrantydetail from './pages/customers/Warrantydetail.js';
import Updatecustomer from './pages/customers/Updatecustomer.js';
import Support from './pages/support/Support.js';
import Addserviceinvoice from './pages/customers/Addserviceinvoice.js';
import Viewchallan from './pages/deliverychallan/Viewchallan.js';
import Updateuser from './pages/usermanagement/Updateuser.js';
import UpdateChallan from './pages/deliverychallan/Updatechallan.js';
import Quote from './pages/quotation/Quote.js';
import Addquote from './pages/quotation/Addquote.js';
import Viewquote from './pages/quotation/Viewquote.js';


const RouteRoute = ({setLoader}) => {

  const [show, setShow] = useState(true);

  const user = JSON.parse(localStorage.getItem('user'));

  const module = user?.modules;

  return (
    <>
    <Routes>
        <Route path='/login' element={<Login setLoader={setLoader}/>}></Route> 
        <Route path='/' element={<ProtectedRoute><Layout setLoader={setLoader} show={show} setShow={setShow}/></ProtectedRoute>}>
        <Route path='/' element={<ProtectedRoute><Dashboard show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/enquiry'>
              <Route index={true} element={<ProtectedRoute><Enquiry show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='new' element={<ProtectedRoute><Addenquiry show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='view/:id' element={<ProtectedRoute><Viewenquiry show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/quote'>
              <Route index={true} element={<ProtectedRoute><Quote show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='addquote' element={<ProtectedRoute><Addquote show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='quotedetail/:invoiceId' element={<ProtectedRoute><Viewquote show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/customer'>
              <Route index={true} element={<ProtectedRoute><Customer show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='new' element={<ProtectedRoute><Addcustomer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='update/:cusId' element={<ProtectedRoute><Updatecustomer show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='view/:id'>
              <Route index={true} element={<ProtectedRoute><Viewcustomer show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='newinvoice' element={<ProtectedRoute><Addinvoice show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='newserviceinvoice' element={<ProtectedRoute><Addserviceinvoice show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='newpayment' element={<ProtectedRoute><Addpayment show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='newservice' element={<ProtectedRoute><Addservice show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='newwarranty' element={<ProtectedRoute><Addwarranty show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='paymentDetail/:paymentId' element={<ProtectedRoute><Viewpayment show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='invoiceDetail/:invoiceId'>
              <Route index={true} element={<ProtectedRoute><Viewinvoice show={show} setLoader={setLoader}/></ProtectedRoute>}/>
                  <Route path='paymentDetail/:paymentId' element={<ProtectedRoute><Viewpayment show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              </Route>
              <Route path='serviceDetail/:serviceId'>
              <Route index={true} element={<ProtectedRoute><Viewservice show={show} setLoader={setLoader}/></ProtectedRoute>}/>
                  <Route path='paymentDetail/:paymentId' element={<ProtectedRoute><Viewpayment show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
                  <Route path='invoiceDetail/:invoiceId'>
              <Route index={true} element={<ProtectedRoute><Viewinvoice show={show} setLoader={setLoader}/></ProtectedRoute>}/>
                  <Route path='paymentDetail/:paymentId' element={<ProtectedRoute><Viewpayment show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              </Route>
              </Route>
              <Route path='warrantyDetail/:warrantyId' element={<ProtectedRoute><Warrantydetail show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              </Route>
        </Route>
        <Route path='/inventory'>
              <Route index={true} element={<ProtectedRoute><Inventory show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/purchase'>
              <Route index={true} element={<ProtectedRoute><Purchases show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='new' element={<ProtectedRoute><Newpurchase show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='detail/:purchaseID' element={<ProtectedRoute><Purchasedetail show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/warranty' element={<ProtectedRoute><Warranty show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/deliverychallan'>
              <Route index={true} element={<ProtectedRoute><Challan show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='addchallan' element={<ProtectedRoute><AddChallan show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
              <Route path='challanDetail/:challanId' element={<ProtectedRoute><Viewchallan show={show} setLoader={setLoader}/></ProtectedRoute>}/>
              <Route path='updatechallan/:challanId' element={<ProtectedRoute><UpdateChallan show={show} setLoader={setLoader}/></ProtectedRoute>}/>
        </Route>
        <Route path='/usermanagement' >
            <Route index={true} element={<ProtectedRoute><Usermanagement show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='createuser' element={<ProtectedRoute><Createuser show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
            <Route path='edituser/:userId' element={<ProtectedRoute><Updateuser show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/report' element={<ProtectedRoute><Report show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        <Route path='/setting' >
            <Route index={true} element={<ProtectedRoute><Setting show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
        </Route>
        <Route path='/support' element={<ProtectedRoute><Support show={show} setLoader={setLoader}/></ProtectedRoute>}></Route>
      </Route>
    </Routes>
    </>
  )
}

export default RouteRoute;