import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomTable from '../table/Table';
import './Tabs.css';
import { Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Select, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getenquiry, updateenquiry } from '../../slices/enquiry';
import CustomTable1 from '../table/Invtable';
import { Link } from 'react-router-dom';
import { getinvoicebycus, getpaymentbycus, getservicebycus, getwarrantybycus } from '../../slices/customer';
import CustomTable2 from '../table/paytable';
import CustomTable3 from '../table/servicetable ';
import CustomTable4 from '../table/warrantytable ';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const columns1 = [
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 200, align:'start' },
  { id: 'total', label: 'Price', minWidth: 100, align:'start' },
  { id: 'pending_payments', label: 'Pending Amount', minWidth: 150, align:'center' },
  { id: 'invoice_date', label: 'Invoice Date', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns2 = [
  { id: 'payment_id', label: 'Payment ID', minWidth: 200, align:'start' },
  { id: 'invoice_id', label: 'Invoice ID', minWidth: 150, align:'center' },
  { id: 'total', label: 'Amount', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns4 = [
  { id: 'warranty_id', label: 'Warranty ID', minWidth: 200, align:'start' },
  { id: 'item', label: 'Item', minWidth: 150, align:'center' },
  { id: 'item_brand', label: 'Item Brand', minWidth: 150, align:'center' },
  { id: 'item_no', label: 'Item No', minWidth: 150, align:'center' },
  { id: 'still_warranty', label: 'Warranty', minWidth: 150, align:'center' },
  { id: 'product_type', label: 'Product type', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

const columns3 = [
  { id: 'service_id', label: 'Service ID', minWidth: 200, align:'start' },
  { id: 'number_service', label: 'Service No', minWidth: 150, align:'center' },
  { id: 'created_at', label: 'Service Date', minWidth: 150, align:'center' },
  { id: 'status', label: 'Status', minWidth: 150, align:'center' },
  // { id: 'action', label: 'Action', minWidth: 100, align:'end', actionType: [{edit: true, delete: false}] },
];

export default function BasicTabs({viewData, setLoader, cusId}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    sessionStorage.clear();
  };

  const {invoices: invoiceData} = useSelector((state) => state.customer) || [];

  const {payments: paymentData} = useSelector((state) => state.customer) || [];

  const {services: serviceData} = useSelector((state) => state.customer) || [];

  const {warrantys: warrantyData} = useSelector((state) => state.customer) || [];


  const vehicledetailsData = (invoiceData.filter(va => va.invoice_type !== "Service")).map(obj => JSON.parse(obj.item_list)).flat();

  console.log(vehicledetailsData);

  const vehicleSelectOption = vehicledetailsData?.filter(ab => ab.product_type === "Vehicle" || !ab.hasOwnProperty("product_type")).map(obj => {return {
    id: obj.id,
    vehicle_name: obj.vehicle.product_name,
    variant_name: obj.variant.variant_name
  }})

  console.log(paymentData);


  const [selected, setSelected] = React.useState(null);

  React.useEffect(()=>{
    if(vehicleSelectOption.length > 0 && selected === null){
      setSelected(vehicleSelectOption[0]?.id);
    }
  }, [vehicleSelectOption])

  const dispatch = useDispatch();


  React.useEffect(()=>{
    setLoader(true)
      dispatch(getinvoicebycus(cusId))
      dispatch(getpaymentbycus(cusId))
      dispatch(getservicebycus(cusId))
      dispatch(getwarrantybycus(cusId))
      .then(() => {
        setLoader(false);
      })
      .catch(()=>{
        setLoader(false);
      })
    const va = sessionStorage.getItem('cusTab');
    if(va){
      setValue(Number(va));
    }
  }, [])

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Personal Details" {...a11yProps(0)} sx={{fontWeight:'bold'}}/>
          <Tab label="Vehicle Details" {...a11yProps(1)} sx={{fontWeight:'bold'}}/>
          <Tab label="Payment" {...a11yProps(2)} sx={{fontWeight:'bold'}}/>
          <Tab label="Invoice" {...a11yProps(3)} sx={{fontWeight:'bold'}}/>
          <Tab label="Service" {...a11yProps(4)} sx={{fontWeight:'bold'}}/>
          <Tab label="Warranty Claim" {...a11yProps(5)} sx={{fontWeight:'bold'}}/>
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>
              <Grid item xs={3}>
              <Typography variant='p' sx={{mt: 0.5}}   >
                      First Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.first_name}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  Last Name                  
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.last_name}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  Phone                 
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.customer_phone_mobile}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  Email              
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.email}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography variant='p'>
                  Billing Address                
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData?.billing_address?.address}, {viewData?.billing_address?.city}, {viewData?.billing_address?.state}, {viewData?.billing_address?.country}, {viewData?.billing_address?.pincode}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
                  <Typography variant='p'>
                  Shipping Address            
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData?.shipping_address?.address}, {viewData?.shipping_address?.city}, {viewData?.shipping_address?.state}, {viewData?.shipping_address?.country}, {viewData?.shipping_address?.pincode}
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  Aadhar No            
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.aadhar_no}
                  </Typography>
                  <img src={viewData.aadhar_doc} alt='proof' width={200}></img>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  PAN No                
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.pan_no}
                  </Typography>
                  <img src={viewData.pan_doc} alt='proof' width={200}></img>
              </Grid>
              <Grid item xs={3}>
                  <Typography variant='p'>
                  Licence No                
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 14}}>
                      {viewData.licence_no}
                  </Typography>
                  <img src={viewData.licence_doc} alt='proof' width={200}></img>
              </Grid>
          </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
      {vehicleSelectOption?.length > 0 &&
      <FormControl size='small' fullWidth>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={selected}
    onChange={(e)=>{
      setSelected(e.target.value)
    }}
  >
  {vehicleSelectOption.map((va, index) => {
    return(
      <MenuItem key={index} value={va.id}>{va.vehicle_name}-{va.variant_name}</MenuItem>
    )
  })}
  </Select>
  </FormControl>
      }
  {vehicledetailsData.filter(obj => obj.id === selected).map((va, index)=>{
    return(
      <Box>
      <Grid container spacing={2} sx={{mt: 1}}>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Product Name
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.vehicle?.product_name}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Chasis No
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.stock?.chasis_no}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Variant Name
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.variant?.variant_name}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      MFT Year
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.vehicle?.mft_year}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Color
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {JSON.parse(va?.variant?.color_details).filter(obj => obj.code === va?.stock?.color)[0]?.name}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      HSN Code
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.vehicle?.hsn_no}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Reg No
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.vehicle?.registration}
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
            <Typography variant='p' sx={{mt: 0.5}}   >
                      Subsidy
                  </Typography>
            </Grid>
            <Grid item xs={1.5}>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                      {va?.variant?.subsidy}
                  </Typography>
            </Grid>
      </Grid>
      <Divider sx={{my: 2}}/>
      <Grid container spacing={2}>
          <Grid item xs={2.9}>
            <Typography variant='h6'>Motor Details</Typography>
            <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Motor No
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.stock?.motor_no}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Motor Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Motor Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_type}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Voltage
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_voltage}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Capacity
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_capacity}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.motor_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item xs={2.9}>
          <Typography variant='h6'>Battery Details</Typography>
          <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Battery No
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.stock?.battery_no}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Battery Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Battery Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_type}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Life Cycle
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_lifecycle}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Capacity
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_capacity}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.battery_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item xs={2.9}>
          <Typography variant='h6'>Charger Details</Typography>
          <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Charger Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Charger Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_type}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Capacity
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_capacity}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.charger_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item xs={2.9}>
          <Typography variant='h6'>Converter Details</Typography>
          <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Converter No
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.stock?.convertor_no}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Converter Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.converter_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Capacity
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.converter_capacity}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.converter_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.converter_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.converter_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
      </Grid>
      <Divider sx={{my: 2}}/>
      <Grid container spacing={2}>
          <Grid item xs={2.9}>
            <Typography variant='h6'>Controller Details</Typography>
            <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Controller No
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.stock?.controller_no}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Controller Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.controller_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Capacity
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.controller_capacity}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.controller_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.controller_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.controller_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item xs={2.9}>
          <Typography variant='h6'>Display Details</Typography>
          <Grid container spacing={2} sx={{mt: 0}}>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Display Name
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.display_name}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Display Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.display_type}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (KM)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.display_warranty_km}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty (M)
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.display_warranty_months}
                  </Typography>
              </Grid>
              <Grid item xs={6}>
              <Typography variant='p'  >
                      Warranty Type
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {va?.variant?.display_warranty_type}
                  </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
          <Grid item xs={2.9}>
          <Typography variant='h6'>Other Details</Typography>
          <Grid container spacing={2} sx={{mt: 0}}>
          {JSON.parse(va?.variant?.other_details)?.map((ab, index)=>{
            return(
              <Grid item xs={6}>
              <Typography variant='p'  >
                      {ab?.field}
                  </Typography>
                  <Typography variant='h6' sx={{fontSize: 15}}>
                  {ab?.value}
                  </Typography>
              </Grid>
            )
          })}
            </Grid>
          </Grid>
          <Divider orientation='vertical' flexItem/>
      </Grid>
      </Box>
    )
  })}
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
      <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb: 2}}>
        <Typography variant='h6'>Payment History</Typography>
        <Link to={`/customer/view/${viewData.id}/newpayment`} state={{cusData: viewData}} onClick={()=> sessionStorage.setItem('cusTab', value)}>
        <Button size='small' variant='contained'> + New Payment</Button>
        </Link>
      </Box>
      <CustomTable2 viewData={viewData} columns={columns2} rows={paymentData}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
      <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb: 2}}>
        <Typography variant='h6'>Invoice History</Typography>
        <Link to={`/customer/view/${viewData.id}/newinvoice`} state={{cusData: viewData}} onClick={()=> sessionStorage.setItem('cusTab', value)}>
        <Button size='small' variant='contained'> + Add Invoice</Button>
        </Link>
      </Box>
      <CustomTable1 viewData={viewData} columns={columns1} rows={invoiceData}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
      <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb: 2}}>
        <Typography variant='h6'>Service History</Typography>
        <Link to={`/customer/view/${viewData.id}/newservice`} state={{cusData: viewData}} onClick={()=> sessionStorage.setItem('cusTab', value)}>
        <Button size='small' variant='contained'> + New Service</Button>
        </Link>
      </Box>
      <CustomTable3 viewData={viewData} columns={columns3} rows={serviceData}/>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
      <Box sx={{display:'flex', justifyContent:'space-between', alignItems:'center', mb: 2}}>
        <Typography variant='h6'>Warranty Claim History</Typography>
        <Link to={`/customer/view/${viewData.id}/newwarranty`} state={{cusData: viewData, warrantyData: warrantyData}} onClick={()=> sessionStorage.setItem('cusTab', value)}>
        <Button size='small' variant='contained'> + New Warranty</Button>
        </Link>
      </Box>
      <CustomTable4 viewData={viewData} columns={columns4} rows={warrantyData}/>
      </CustomTabPanel>
    </Box>
  );
}